import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SkylarkPolygonLayer from '../core/skylarkPolygonLayer';
import { center } from '@turf/turf';
import { useNavigate, useLocation } from 'react-router-dom';
import { bbox } from '@turf/turf';  // Bounding box function from Turf
import resetMap from '../core/resetMap';

import {
  fmesh,
  fullmesh,
} from '../core/globalVariables.js';
import supabaseClient from '../../supabaseClient';
import BarLoader from "react-spinners/BarLoader";

const LoadMenu = ({
  isMenuLoadClick,
  closeLoadMenu,
  globalRefs,
  globalState,
  mapLoaded,
  setLoggedInToAPI,
  setLoadedPercentage,
}) => {

  const [mapData, setMapData] = useState([]);
  const [mapDetails, setMapDetails] = useState(null);
  const [clickedMapId, setClickedMapId] = useState(null);
  const [initFetch, setInitFetch] = useState(0);
  const [isLoadingThumbnails, setIsLoadingThumbnails] = useState(false);

  const setIsLoading = globalState.setIsLoading;
  const mapRef = globalRefs.mapRef;
  const polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
  const longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  const originalGeoJSONforComparingRef = globalRefs.originalGeoJSONforComparingRef;
  const setisMenuLoadClick = globalState.setisMenuLoadClick;
  const setCurrentMapId = globalState.setCurrentMapId;

  let workingLocally = globalState.workingLocally;
  let setUserEmail = globalState.setUserEmail;
  let centroidRef = globalRefs.centroidRef;
  let universalParcelDifferentiator = globalState.universalParcelDifferentiator;
  let setUniversalParcelDifferentiator = globalState.setUniversalParcelDifferentiator;
  let universalColorPaletteName = globalState.universalColorPaletteName;

  let adminUser = globalState.adminUser;
  let setAdminUser = globalState.setAdminUser;

  let globalPublicSelectedDataset = globalState.globalPublicSelectedDataset;
  let setGlobalPublicSelectedDataset = globalState.setGlobalPublicSelectedDataset;

  const location = useLocation();

  // FETCH ALL MAPS FOR THE LOAD MENU - - - - - - - 
  // FETCH ALL MAPS FOR THE LOAD MENU - - - - - - - 
  // FETCH ALL MAPS FOR THE LOAD MENU - - - - - - - 

  useEffect(() => {
    if (isMenuLoadClick === true) {
      setIsLoadingThumbnails(true);
      const fetchAllMapDataForLoadMenu = async () => {
        try {
          const token = localStorage.getItem('token');
          let data;
          if (workingLocally) {
            // Fetching data locally with axios
            const response = await axios.get('/api/map', { withCredentials: true });
            console.log(response.data); // The response is already parsed
            data = response.data; // Assign the array directly
          } else {
            // Fetching data from live environment
            const response = await fetch(`https://harrierapi9697.site/map/?token=${encodeURIComponent(token)}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            data = await response.json(); // Parse the JSON response when working with fetch
          }
          setMapData(data);
          // Fetch thumbnails for each map
          try {
            const thumbnails = await Promise.all(
              data.map(async (map) => {
                const { data: thumbnailData, error } = await supabaseClient
                  .from('images')
                  .select('base64')
                  .eq('name', map.id)
                  .single();
                if (error) {
                  return { id: map.id, thumbnail: null };
                }
                if (thumbnailData && thumbnailData.base64) {
                  const cleanBase64 = thumbnailData.base64.replace(
                    /^(data:image\/[a-zA-Z]+;base64,)+/,
                    'data:image/png;base64,'
                  );
                  return { id: map.id, thumbnail: cleanBase64 };
                } else {
                  return { id: map.id, thumbnail: null };
                }
              })
            );
            setMapDetails((prevMapDetails) => ({
              ...prevMapDetails,
              thumbnails: thumbnails.reduce((acc, { id, thumbnail }) => {
                acc[id] = thumbnail;
                return acc;
              }, {}),
            }));
            setIsLoadingThumbnails(false);
          } catch (error) {
            console.error('Error fetching thumbnails:', error);
          }
        } catch (error) {
          console.error('Error fetching map data:', error);
        }
      };
      fetchAllMapDataForLoadMenu();
    }
  }, [isMenuLoadClick]);

  // STAGE 01 - IS THE MAP LOADED? IF SO, FETCH USER DATA - - - - - - - - - - - - - - - -
  // STAGE 01 - IS THE MAP LOADED? IF SO, FETCH USER DATA - - - - - - - - - - - - - - - -
  // STAGE 01 - IS THE MAP LOADED? IF SO, FETCH USER DATA - - - - - - - - - - - - - - - -

  useEffect(() => {
    if (mapData && initFetch === 0 && mapLoaded) {
      getUserInfo();
      setInitFetch(1);
    }
  }, [mapData, initFetch, mapLoaded]);

  // STAGE 02 - USER DATA (IS USER LOGGED IN, IF SO, WHO?) - - - - - - - - - - - - - - - -
  // STAGE 02 - USER DATA (IS USER LOGGED IN, IF SO, WHO?) - - - - - - - - - - - - - - - -
  // STAGE 02 - USER DATA (IS USER LOGGED IN, IF SO, WHO?) - - - - - - - - - - - - - - - -

  const getUserInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      let response;
      if (!token) {
        throw new Error('No token in local storage');
      } else {
      }
      if (workingLocally) {
        // LOCAL - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
        response = await axios.get(`/api/info`, { withCredentials: true });
        console.log('response:', response.data.user.email);
        setUserEmail(response.data.user.email);

        // REIDRECT CLIENT USERS BEGIN LOCAL - - - - 
        // REIDRECT CLIENT USERS BEGIN LOCAL - - - - 
        // REIDRECT CLIENT USERS BEGIN LOCAL - - - - 
        // REIDRECT CLIENT USERS BEGIN LOCAL - - - - 

        let userUIDCH = process.env.REACT_APP_Sh0lt0Re1r3ctFr0mHarri3rUID;
        let userUIDCW = process.env.REACT_APP_Sh0lt0Re1r3ctFr0mHarri3rCornwallUID;

        if (response.data.user.id === userUIDCH) {
          console.log(token)
          setAdminUser(true);
          window.location.href = '/castlehoward?' + token;
        }

        console.log(response.data.user.id);

        if (response.data.user.id === userUIDCW) {
          console.log(token)
          setAdminUser(true);
          window.location.href = '/cornwall?' + token;
        }

        // REIDRECT CLIENT USERS END LOCAL - - - - 
        // REIDRECT CLIENT USERS END LOCAL - - - - 
        // REIDRECT CLIENT USERS END LOCAL - - - - 
        // REIDRECT CLIENT USERS END LOCAL - - - - 

        setIsLoading(false);
        setLoggedInToAPI(true);
      } else {
        // LIVE - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
        response = await fetch(`https://harrierapi9697.site/info/?token=${encodeURIComponent(token)}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        setUserEmail(data.user.email);

        // REIDRECT CLIENT USERS BEGIN LIVE- - - - 
        // REIDRECT CLIENT USERS BEGIN LIVE- - - - 
        // REIDRECT CLIENT USERS BEGIN LIVE- - - - 
        // REIDRECT CLIENT USERS BEGIN LIVE- - - - 

        // const queryParams = new URLSearchParams(location.search);
        // console.log(queryParams)
        // const token = queryParams.get('token');
        // console.log(token)

        console.log(data.user.id);

        let userUID = process.env.REACT_APP_Sh0lt0Re1r3ctFr0mHarri3rUID;
        if (data.user.id === userUID) {
          console.log(token)
          setAdminUser(true);
          window.location.href = '/castlehoward?token=' + token;
        }

        if(data.user.id === "dbef8cca-b2f7-4374-823a-2a8c2e79c471") {
          console.log(token)
          setAdminUser(true);
          window.location.href = '/cornwall?token=' + token;
        }

        // REIDRECT CLIENT USERS END LIVE - - - - 
        // REIDRECT CLIENT USERS END LIVE - - - - 
        // REIDRECT CLIENT USERS END LIVE - - - - 
        // REIDRECT CLIENT USERS END LIVE - - - - 

        setIsLoading(false);
        setLoggedInToAPI(true);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
      console.log("user not logged in");
      setLoggedInToAPI(false);
      loadPublicData();
    }
  };

  useEffect(() => {
    loadPublicData();
  }, [globalPublicSelectedDataset]);

  // STAGE 03 - LOAD PUBLIC DATA - - - - - - - - - - - - - - - - REGARDLESS OF LOG IN RESULT
  // STAGE 03 - LOAD PUBLIC DATA - - - - - - - - - - - - - - - - REGARDLESS OF LOG IN RESULT
  // STAGE 03 - LOAD PUBLIC DATA - - - - - - - - - - - - - - - - REGARDLESS OF LOG IN RESULT

  const loadPublicData = async () => {
    try {

      const response = await axios.get('/data/' + globalPublicSelectedDataset + '.json');
      setLoadedPercentage(60);
      const geoJsonData = response.data;
      centroidRef.current = center(geoJsonData);
      longstandingGeoJSONRef.current = geoJsonData;

      // IF THERE IS ALREADY A DATA LAYER, REMOVE IT - - - - - - - 
      // IF THERE IS ALREADY A DATA LAYER, REMOVE IT - - - - - - - 
      // IF THERE IS ALREADY A DATA LAYER, REMOVE IT - - - - - - - 
      fmesh.children.forEach((child) => {
        child.geometry.dispose();
        child.material.dispose();
      });
      fullmesh.children.forEach((child) => {
        fullmesh.remove(child);
      });
      fmesh.children.forEach((child) => {
        fmesh.remove(child);
      });
      fmesh.children.length = 0;
      fullmesh.children.length = 0;
      // CREATE NEW POLYGON LAYER - - - - - - -
      // CREATE NEW POLYGON LAYER - - - - - - -
      // CREATE NEW POLYGON LAYER - - - - - - -
      // CREATE NEW POLYGON LAYER - - - - - - -

        if (mapRef.current && mapRef.current.getLayer('Polygons')) {
            mapRef.current.removeLayer('Polygons');
        }
        polygonCustomLayerRef.current = new SkylarkPolygonLayer(
            'Polygons',
            centroidRef.current.geometry.coordinates[1],
            centroidRef.current.geometry.coordinates[0],
            longstandingGeoJSONRef.current,
            globalRefs,
            globalState,
            universalColorPaletteName,
            universalParcelDifferentiator
        );
        mapRef.current.addLayer(polygonCustomLayerRef.current);
        mapRef.current.on('mousedown', (e) => {
            if (e.originalEvent.button === 0) {
                polygonCustomLayerRef.current.raycast(e.point, false);
            }
        });
        // mapRef.current.on('zoom', () => {
        //     console.log('zoom');
        //     // console.log(mapRef.current.getZoom());
        //     let zoomLevel = mapRef.current.getZoom();
        //     polygonCustomLayerRef.current.zoomBased_Animate_Larger(zoomLevel);
        // });
        const bounds = bbox(longstandingGeoJSONRef.current);
        mapRef.current.fitBounds([[bounds[0], bounds[1]], [bounds[2], bounds[3]]], {
            padding: 20,
            speed: 1
        });

    } catch (error) {
      console.error('Error loading public data:', error);
    }
  };

  // SET WHICH MAP FROM THE LOAD MENU HAS BEEN SELECTED - - - - - - - -
  // SET WHICH MAP FROM THE LOAD MENU HAS BEEN SELECTED - - - - - - - -
  // SET WHICH MAP FROM THE LOAD MENU HAS BEEN SELECTED - - - - - - - -

  const fetchMapDetailsSelected = async (id) => {
    setClickedMapId(id);
  };

  // LOAD THEH MAP FROM THE LOAD MENU WHICH HAS BEEN SELECTED - - - - - - - -
  // LOAD THEH MAP FROM THE LOAD MENU WHICH HAS BEEN SELECTED - - - - - - - -
  // LOAD THEH MAP FROM THE LOAD MENU WHICH HAS BEEN SELECTED - - - - - - - -

  const loadDataBtn = () => {
    fetchMapDetails(clickedMapId);
    setisMenuLoadClick(false);
    setIsLoading(true);
  };

  let isThisFirstLoadOfNewData = 0;

  // FETCH MAP GEOJSON DATA WHEN A MAP IS SELECTED FROM THE LOAD MENU - - - - - - - -
  // FETCH MAP GEOJSON DATA WHEN A MAP IS SELECTED FROM THE LOAD MENU - - - - - - - -
  // FETCH MAP GEOJSON DATA WHEN A MAP IS SELECTED FROM THE LOAD MENU - - - - - - - -

  const fetchMapDetails = async (id) => {
    setCurrentMapId(id);
    setisMenuLoadClick(false);
    async function fetchMapDetails(mapId) {
      try {
        const token = localStorage.getItem('token');
        let response;
        let geoJsonData;
        if (!token) {
          throw new Error('No token in local storage');
        } else {
          // LOCAL - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          if (workingLocally) {
            response = await axios.get(`/api/map/${id}`, { withCredentials: true });
          } else {
            // LIVE - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            response = await fetch(`https://harrierapi9697.site/map/${id}?token=${encodeURIComponent(token)}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
          }
          // LOCAL - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          if (workingLocally) {
            geoJsonData = response.data;
          } else {
            // LIVE - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            if (!response.ok) {
              throw new Error(`Error fetching map details: ${response.statusText}`);
            }
            geoJsonData = await response.json();
          }
          loggedInDataLoad(geoJsonData);
          getUserInfo();
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
    fetchMapDetails(id);
  };

  // LOAD MAPS GEOJSON DATA AND POPULATE RELAVENT FUNCTIONS WITH NECESSARY PARAMETERS - - - - - - - -
  // LOAD MAPS GEOJSON DATA AND POPULATE RELAVENT FUNCTIONS WITH NECESSARY PARAMETERS - - - - - - - -
  // LOAD MAPS GEOJSON DATA AND POPULATE RELAVENT FUNCTIONS WITH NECESSARY PARAMETERS - - - - - - - -

  const loggedInDataLoad = async (geoJsonData) => {
    centroidRef.current = center(geoJsonData);
    setMapDetails(geoJsonData);
    longstandingGeoJSONRef.current = geoJsonData;
    longstandingGeoJSONRef.current.centroidRef = centroidRef.current.geometry.coordinates;
    let newDataArray = [];
    let invalidFeatures = [];
    // IF THIS THIS THE FIRST LOAD OF THE NEW DATA, POPULATE ORIGINALGEOJSONREF FOR USE IN CAMPARING - - - - - - - 
    // IF THIS THIS THE FIRST LOAD OF THE NEW DATA, POPULATE ORIGINALGEOJSONREF FOR USE IN CAMPARING - - - - - - - 
    // IF THIS THIS THE FIRST LOAD OF THE NEW DATA, POPULATE ORIGINALGEOJSONREF FOR USE IN CAMPARING - - - - - - - 
    if (isThisFirstLoadOfNewData == 0) {
      originalGeoJSONforComparingRef.current = JSON.parse(JSON.stringify(geoJsonData));
      isThisFirstLoadOfNewData = 1;
    }
    for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
      let feature = { ...longstandingGeoJSONRef.current.features[i] };
      delete feature.type;
      const coordinates = feature.geometry.coordinates[0];
      if (coordinates.length < 4) {
        invalidFeatures.push(feature);
      } else {
        newDataArray.push(feature);
      }
    }
    setLoadedPercentage(60);
    if (mapRef.current) {
      // IF THERE IS ALREADY A DATA LAYER, REMOVE IT - - - - - - - 
      // IF THERE IS ALREADY A DATA LAYER, REMOVE IT - - - - - - - 
      // IF THERE IS ALREADY A DATA LAYER, REMOVE IT - - - - - - - 
      if (initFetch === 1) {
        fmesh.children.forEach((child) => {
          child.geometry.dispose();
          child.material.dispose();
        });
        fullmesh.children.forEach((child) => {
          fullmesh.remove(child);
        });
        fmesh.children.forEach((child) => {
          fmesh.remove(child);
        });
        fmesh.children.length = 0;
        fullmesh.children.length = 0;
        mapRef.current.removeLayer('Polygons');
      }
      // CREATE NEW POLYGON LAYER - - - - - - - 
      // CREATE NEW POLYGON LAYER - - - - - - - 
      // CREATE NEW POLYGON LAYER - - - - - - - 
      polygonCustomLayerRef.current = new SkylarkPolygonLayer(
        'Polygons',
        centroidRef.current.geometry.coordinates[1],
        centroidRef.current.geometry.coordinates[0],
        longstandingGeoJSONRef.current,
        globalRefs,
        globalState,
        universalColorPaletteName,
        universalParcelDifferentiator
      );
      // ADD NEW POLYGON LAYER TO MAP - - - - - - - 
      // ADD NEW POLYGON LAYER TO MAP - - - - - - - 
      // ADD NEW POLYGON LAYER TO MAP - - - - - - - 
      mapRef.current.addLayer(polygonCustomLayerRef.current);
    }
    mapRef.current.on('mousedown', (e) => {
      if (e.originalEvent.button === 0) {
        polygonCustomLayerRef.current.raycast(e.point, false);
      }
    });
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (
    isMenuLoadClick ? (
      <div className='file_dropdown_list'>
        <div className='APIMapLoad'>
          <div className="closeBtnCont">
            <span className="closeLoadBtn" onClick={closeLoadMenu}>
              Cancel
            </span>
            <span className="openLoadBtn" onClick={loadDataBtn}>
              Open
            </span>
          </div>
          <div className='APIMapLoadInnter'>
            {isLoadingThumbnails ? (
              <div className="loadingThumbnails">
                Loading Maps
                <div className="loadingThumbnailsSpinner">
                  <BarLoader />
                </div>
              </div>
            ) : (
              <ul className='map-list'>
                {(() => {
                  const startIndex = mapData.findIndex(map => map.name === "3007 1332");
                  const filteredMapData = startIndex !== -1 ? mapData.slice(startIndex + 1) : mapData;
                  return filteredMapData.map((map, index) => (
                    <li
                      key={index}
                      onClick={() => fetchMapDetailsSelected(map.id)}
                      className={`map-item ${clickedMapId === map.id ? 'highlighted' : ''}`}
                    >
                      <div className='map-list-info-cont'>
                        <div className="LoadMenuscreenshot">
                          {mapDetails.thumbnails && mapDetails.thumbnails[map.id] ? (
                            <img
                              src={`${mapDetails.thumbnails[map.id]}`}
                              alt={map.title}
                              className='thumbnail'
                            />
                          ) : (
                            <div className="placeholder-thumbnail">No Image</div>
                          )}
                        </div>
                        <div className='map-item-name'>
                          <span className='map-name'>{map.title}</span>
                        </div>
                      </div>
                    </li>
                  ));
                })()}
              </ul>
            )
            }
          </div>
        </div>
      </div>
    ) : null
  )
};

export default LoadMenu;
