import supabaseClient from '../../supabaseClient';

async function saveDataWithFilename(globalRefs, globalFileStates, globalState, base64image) {
  let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  let newFilename = globalFileStates.newFilename;
  let setNewFilename = globalFileStates.setNewFilename;
  let setMapSavedWithFilenameSuccess = globalState.setMapSavedWithFilenameSuccess;
  let selectedYearEditing = globalState.selectedYearEditing;

  function generateUniqueId() {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
    for (let i = 0; i < 36; i++) {
      if (i === 8 || i === 13 || i === 18 || i === 23) {
        id += '-';
      } else {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
    }
    return id;
  }

  const mapId = generateUniqueId();
  const token = localStorage.getItem('token');

  const featureHeader = longstandingGeoJSONRef.current?.features?.[0]?.properties
    ? Object.keys(longstandingGeoJSONRef.current.features[0].properties).reduce((acc, key) => {
        acc[key] = {
          name: key,
          external_url: `https://example.com/${key}`,
          description: `Description for ${key}`,
        };
        return acc;
      }, {})
    : {};

  const featureDataSimple = longstandingGeoJSONRef.current?.features?.[0]?.feature_data_simple_timeseries
    ? Object.keys(longstandingGeoJSONRef.current.features[0].feature_data_simple_timeseries).reduce((acc, key) => {
        acc[key] = {
          name: key,
          type: typeof longstandingGeoJSONRef.current.features[0].feature_data_simple_timeseries[key],
          description: `Description for ${key}`,
          unit: null,
          enum: null,
        };
        return acc;
      }, {})
    : {};

  const bodyContent = {
    id: mapId,
    title: newFilename,
    owner: {
      id: mapId,
      email: "test@skylark.land",
    },
    public: false,
    feature_header: {
      define_external_ids: featureHeader,
      define_feature_data_simple: featureDataSimple,
    },
  };

  const response = await fetch(`https://harrierapi9697.site/api/maps?token=${encodeURIComponent(token)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyContent),
    credentials: 'include',
  });

  const data = await response.json();

  if (response.ok) {
    const dataUpload = longstandingGeoJSONRef.current;
    const newDataArray = [];
    const invalidFeatures = [];

    for (const feature of dataUpload.features) {
      const sanitizedFeature = { ...feature };
      delete sanitizedFeature.type;
      delete sanitizedFeature.skylark_id;

      const coordinates = sanitizedFeature.geometry.coordinates?.[0];
      if (!coordinates || coordinates.length < 4) {
        console.error(`Feature has an incorrect number of coordinates:`, coordinates);
        invalidFeatures.push(sanitizedFeature);
      } else {
        newDataArray.push(sanitizedFeature);
      }
    }

    const featureResponse = await fetch(`https://harrierapi9697.site/api/maps/${mapId}/features?token=${encodeURIComponent(token)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newDataArray),
      credentials: 'include',
    });

    if (featureResponse.ok) {
      setMapSavedWithFilenameSuccess(true);
      try {
        const { data, error } = await supabaseClient
          .from('images')
          .insert([{ name: mapId, base64: base64image }]);
        if (error) console.error('Error inserting image:', error);
      } catch (error) {
        console.error('Error with Supabase:', error);
      }
    } else {
      console.error('Error uploading features:', await featureResponse.text());
    }
  } else {
    console.error('Error creating map:', data);
  }
}

export default saveDataWithFilename;
